$timelineHeight: 1.5rem;
$timelineColor: #989898;
$timelineColorLight: #cac9c9;
$timelineColorActive: #f50057;

.timeline {
  position: relative;
  padding: 0.5rem 0;
  overflow: hidden;
}

.timeline-point-wrapper {
  display: grid;
  grid-template: #{$timelineHeight} auto / 1fr;
  grid-row-gap: 0.5rem;

  justify-items: center;

  &:first-of-type {
    justify-items: flex-start;
  }

  &:last-of-type {
    justify-items: flex-end;
  }

  &:last-of-type:first-of-type {
    justify-items: center;
  }
}

.timeline-line {
  position: relative;
  top: 0.8rem;
  width: 100%;
  height: 2px;
  margin: 0;
  background-color: $timelineColor;
}

.timeline-points {
  position: relative;
  display: grid;
  grid-template: 1fr / minmax(5.3rem,1fr);
  grid-auto-flow: column;
  grid-auto-columns: minmax(5.3rem, 1fr);
  grid-column-gap: 0.3rem;
  width: 100%;
  justify-content: space-between;
  margin: 0;
  z-index: 1;
}

.timeline-point {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $timelineHeight;
  width: $timelineHeight;
  border-radius: 0.75rem;
  background-color: $color-background-default;
  font-size: 0.7rem;
  font-weight: 500;
  color: $color-text;

  cursor: pointer;

  &.active {
    background-color: $color-highlight;
    font-weight: bold;
    color: $color-white;
    cursor: default;
  }

  &:hover,
  &:active {
    color: $color-white;
    background-color: $color-highlight;
  }
}

.timeline-point-label {
  color: black;
  font-size: 0.5rem;
}

.timeline-base {
  display: grid;
  grid-template: 1fr /  1fr ;
  grid-column-gap: 0.5rem;
  width: 100%;
  background-color: white;
  border-radius: 3px;
}

.button {
  width: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;

  cursor: pointer;
}

.timeline-slider {
  position: relative;
  display: grid;
  grid-template: auto / 1fr;

  transition: left 0.5s;
}

.timeline-wrapper {
  display: grid;
  grid-template: auto auto / 1fr;
  grid-row-gap: 0.5rem;
}

.timeline-buttons {
  display: grid;
  grid-template: 1fr / repeat(4,1fr);
  grid-gap: 0.5rem;
  width: calc(100% - 0.4rem);
  background-color: white;
  border-radius: 3px;
  padding: 0.2rem;

}


.timeline-button {
  display: flex;
  justify-content: center;
  align-items: center;

  height: 1rem;
  width: 1rem;
  font-size: 0.8rem;
  color: black;
  justify-self: flex-start;
  padding: 0.1rem;
  border-radius: 2px;
  cursor: pointer;

  &:hover,
  &:active {
    background-color: $timelineColorLight;
  }

  &:nth-of-type(2),
  &:nth-of-type(4) {
    justify-self: flex-end;
  }
}