@import "generalСlasses.scss";

.headerButtonContainer{
  @include header-button-container-mixin;
  min-width: 300px;
}
.dialogContainer{
  padding-top: 10px !important;
  display: grid;
  grid-gap: 10px;
}
.dialogTitle{
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 0;
}
