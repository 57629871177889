@import "generalСlasses.scss";

.summaryContainer{
  @include summary-container-mixin;
}
.summaryTabMenuHeader{
  @include tab-menu-header-mixin;
}

.summaryMain{
  @include main-scroll-container-mixin;
}

.headerButtonContainer{
  @include header-button-container-mixin;
  min-width: 300px;
}

.headerButtonContainerAdaptive{
  @include header-button-container-adaptive-mixin (1200px)
}

.formContainer{
  @include form-container-mixin;
}
.formRow{
  @include form-row-mixin;
}

.formContainerSection{
  @include form-section-container-mixin;
}


.rowContainerSection{
  display: grid;
  grid-template: 1fr/40px 200px 200px auto;
  grid-auto-flow: column;
  column-gap: 5px;
}
.codeNodeHelpText{
  color: #5856d6;
  padding: 5px 0 10px 5px;
  font-size: 0.9rem;
}
