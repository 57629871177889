//======== .ZstFilter component ============

.ZstFilter-root {
	margin-right: 8px; // Make space for removeFilter button

	// Tweak adornment rendering
	.MuiOutlinedInput-adornedEnd {
		padding-right: 0; 
	}
	.MuiInputAdornment-positionEnd {
		margin-left: 0;
	}

	// Dropwdown fixes
	.MuiSelect-select {
		&:focus {
		background: transparent;
		}
	}
	.MuiSelect-select	~ .MuiSelect-icon {
			display: none;
	}

	// Datepicker fixes
	::-webkit-calendar-picker-indicator {
    margin-inline-start: 0;
    margin-right: 6px;
	}

	// Tweak label/placeholder rendering
	:not(.MuiInputLabel-shrink)[class*="FilterInputLabel"] {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
		transform: translate(14px, 12px) scale(1);
	}
	
	// clearFilter button
	.clearFilter {
		padding: 6px;
	}
	
	// applyFilter button
	.applyFilter {
		padding: 2px 8px;
		margin-right: 6px;
		font-size: 0.8750em;
		background-color: $color-primary !important;
		&:hover {
			opacity: .8;
		}
	}
	
	// removeFilter button
	.removeFilter {
		position: absolute;
		z-index: 1;
		opacity: 1;
    right: -17px;
    top: -14px;
    padding: 6px;
		.MuiSvgIcon-root {
			background: #fff;
			border-radius: 100%;
			path {
				fill: $color-additional !important;
			}
		}
		&:hover {
			.MuiSvgIcon-root path {
				fill: $color-primary !important;
			}
		}
	}
}