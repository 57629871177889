@import "generalСlasses.scss";
.summaryContainer{
  @include summary-container-mixin;
}
.summaryTabMenuHeader{
  @include tab-menu-header-mixin;
}

.summaryMain{
  @include main-scroll-container-mixin;
}

.headerButtonContainer{
  @include header-button-container-mixin;
  min-width: 300px;
}

.formContainer{
  display: grid;
  width: 100%;
  height: 100%;
  grid-template: auto/ 1fr 1fr;
  gap: 20px;
  padding: 10px 20px 20px 20px;
  align-content:start;
  overflow: auto;

  @media screen and (max-width:900px) {
    grid-template-columns:1fr;
    grid-auto-flow: row;
    row-gap: 15px;
    margin-bottom: 20px;
  }
}
