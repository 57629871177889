//======== Custom components ============

// Key value component

.key-value-wrapper {
  display: flex;
  padding: $spacing-xs 0;
  border-width: 1px;
  border-style: solid;
  // Only style border top
  border-left: none; border-right: none; border-bottom: none;
  .key {
    width: 150px;
    padding-right: $spacing-xs;
    font-weight: $fw-bold;
  }
}

// Diagram wrapper

.diagram-wrapper {
  // @include rounded-corners;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 2rem;

  img {
    max-width: 100%;
  }
}

// Footer

.page-footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	.inner {
		padding: $spacing-footer;
		text-align: right;
		span {
			font-size: $fs-s;
			color: $color-body-copy;
		}
	}
}