.buttonContainer{
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.helperText{
  word-break: break-word;
  padding-top:3px;
  text-align: center !important;
}
