@import "generalСlasses.scss";

.summaryContainer{
  @include summary-container-mixin;
}
.summaryTabMenuHeader{
  @include tab-menu-header-mixin;
}

.summaryMain{
  @include main-scroll-container-mixin;
}

.headerButtonContainer{
  @include header-button-container-mixin;
  min-width: 300px;
}

.headerButtonContainerAdaptive{
  @include header-button-container-adaptive-mixin (1200px)
}

.formContainer{
  @include form-container-mixin;
}
.formRow{
  @include form-row-mixin;

  @media screen and (max-width: 900px) {
    grid-auto-flow: row;
    grid-template-columns:  repeat(auto-fill, 200px);
    row-gap: 15px;
  }
}
.formContainerSection{
  @include form-section-container-mixin;
}

.hierarchySection{
  padding-bottom: 20px;
  @media screen and (max-width: 500px) {
    display: grid
  }
}
.hierarchySectionHelperText{
  text-align: center;
}
