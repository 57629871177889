.hierarchy-tabbed-card {
  height: 100%;
}

.hierarchy-content {
  height: 100%;
}

.wrapper {
  display: grid;
  grid-template: auto 1fr / 1fr;
}


.hierarchy-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
}

.loading {
  height: 84vh;
  width: 100%;

}

.divider {
  height: 1px;
  width:100%;
  background-color: $slate;
}