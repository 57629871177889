//======== Global config ============

@include box-sizing-reset(border-box);
@include body-fonts($font-stack-default, $fw-regular);

//======== Toastify override ============

.Toastify__toast--default {
  box-shadow: none !important;
  overflow: visible !important;
  background: transparent !important;
}

//======== Mui override ============

// ### Layout & grid

// Temporary fix for sidebar scrolling

@include device(desktop) {
  .MuiContainer-maxWidthMd {
    max-width: 1280px !important;
  }
  .MuiContainer-maxWidthLg {
    max-width: 1400px !important;
  }
}

// ### UI elements

// ### App header: MuiToolbar

#sidebar-wrapper {

  .MuiToolbar-gutters {
      padding-left: 0;
  }

  .MuiIconButton-edgeStart,
  .toolbar .MuiIconButton-root {
      margin-left: 0;
      padding-right: 5px;
      padding-left: 16px;
  }

}

// ### Input elements and buttons

// Disable ripple effect (incompatible with top-aligned MuiTable)

.MuiIconButton-root:hover,
.MuiIconButton-root:active {
  background: transparent !important;
  opacity: .8;
}

.MuiTouchRipple-root {
  display: none;
}

// Filter box tweak (label positioning)

.content-filter .MuiFormLabel-root {
    transform: translate(14px, 12px) scale(1);
}

// ### Tables

.MuiTable-root {
  th,
  td {
    vertical-align: center;
    padding: 16px !important;
  }
  .MuiButtonBase-root {
    padding: 0 !important;
  }

  .MuiButtonBase-root.table-inline-button {
    padding: 2px 7px!important;
    margin-right: 5px;
  }
}

// #### Spacings

#page-content {
  .MuiCardHeader-root {
    padding: $spacing-bubble-box !important;
    padding-bottom: 0 !important;
  }
  .MuiCardContent-root {
    padding: $spacing-bubble-box !important;
    .MuiContainer-root {
      padding: 0 !important;
    }
  }
  .MuiCardActions-root {
    padding: $spacing-bubble-box !important
  }
  .MuiButton-root {
    max-height: 40px;
  }
  .MuiButton-contained:not(.applyFilter) {
    padding: $spacing-button-default;
  }
  .MuiPaper-rounded .MuiPaper-rounded {
    margin-left: 0 !important; // Nested tables (temporary solution)
  }

  // Make space for footer below content

  > .MuiContainer-root {
    padding-bottom: $spacing-xxl !important;
  }
}

// #### Border radius

.MuiButton-root,
.MuiInputBase-root {
  border-radius: $border-radius-input !important;
}

.MuiPaper-rounded {
  border-radius: $border-radius-bubble-box !important;
  overflow: hidden;
}

.MuiPaper-rounded .MuiPaper-rounded {
 border-radius: 0 !important; // Nested tables (temporary solution)
  th {
    border-radius: 0 !important; // Nested tables (temporary solution)
  }
}

// #### Elevation rules

.MuiPaper-elevation1,
.MuiPaper-elevation2 {

  // No stacked elevation

  .MuiButton-root,
  .MuiPaper-elevation1,
  .MuiPaper-elevation2 {
    box-shadow: none !important;
  }

}
