@charset "UTF-8";

@import

	// Abstract

	'partials/abstract/tokens',
	'partials/abstract/mixins',

	// Temporary

	'partials/components/legacy',


	// Components

	'partials/components/fonts',
	'partials/components/overrides',
	'partials/components/additional-ui-components',
  'partials/components/timeline',
  'partials/components/history',
  'partials/components/movementmap',
  'partials/components/hierarchy',
  'partials/components/filter',

	// Themes

	'partials/themes/light',
	'partials/themes/dark';


.hover-copy {
	&:hover,
	&:active,
	&:focus{
		height: 100%;
		background-color: #e6e8f4;
		padding: 2px 7px;
		border-radius: 4px;

		cursor: pointer;
	}
}

.hover-copy-done {
	&:hover,
	&:active,
	&:focus{
		height: 100%;
		background-color: #00C3A0;
		padding: 2px 7px;
		border-radius: 4px;
	}
}

.select-text-field {
	width: 100%;
	height: 100%;
	& div {
		height: 100%;
	}
}