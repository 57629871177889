@import "generalСlasses.scss";

.timezoneHeader {
  font-size: 1.3em;
}

.summaryMain {
  @include main-scroll-container-mixin;
  height: 100%;
}

.headerButtonContainer {
  @include header-button-container-mixin;
  min-width: 300px;
  margin-top: 0;
}

.formContainer {
  @include form-container-mixin;
  padding: 0;
}

.formRow {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(150px, auto) minmax(180px, auto);
  grid-gap: 10px;
  padding: 20px;
  box-shadow: 0 5px 15px #e2e2e2;
}

.formContainerSection {
  @include form-section-container-mixin;
  padding: 20px;
  margin-bottom: 10px;
  box-shadow: 0 -5px 15px #e2e2e2;
}

.inactivityHoursRowContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 300px);
  grid-template-rows: auto auto;
  grid-column-gap: 20px;
  padding: 15px;
  grid-row-gap: 15px;
}

.inactivityHoursRow {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 150px));
  grid-column-gap: 10px;
  padding: 5px;
  border-radius: 6px;
  box-shadow: 0 0 10px #e2e2e2;
}

.inactivityHoursRowHeader {
  padding: 5px 5px 10px 5px;
  grid-column: span 2;
}

.timezoneSelectInput {
  width:180px;
}
