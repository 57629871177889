@mixin header-height{
  height: 64px;
}

@mixin header-button-container-mixin{
  display: grid;
  grid-auto-flow: column;
  justify-content: flex-end;
  grid-gap: 10px;
  margin: 20px 20px 0 20px;
}

@mixin header-button-container-adaptive-mixin ($max-width){
  position: absolute;
  z-index: 60;
  top: 0;
  right: 0;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;
  margin: 15px 30px 10px 0;
  background-color: white;

  @media screen and (max-width: $max-width) {
    position: relative;
    justify-content: flex-end;
  }
}

@mixin summary-container-mixin{
  position: relative;
  height: 100%;
  display: grid;
  grid-template: auto 1fr / auto;
  grid-auto-flow: row;
}

@mixin tab-menu-header-mixin{
  box-shadow: none;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
@mixin main-scroll-container-mixin{
  position:relative;
  display: grid;
  grid-template: auto 1fr / 1fr;
  overflow: hidden;
  box-shadow: none;
}

@mixin form-container-mixin{
  display: grid;
  width: 100%;
  height: 100%;
  row-gap: 20px;
  padding: 20px 20px 20px 20px;
  align-content: flex-start;
  overflow: auto;
}
@mixin form-row-mixin{
  width: 100%;
  display: grid;
  grid-template: 1fr / minmax(200px,1fr) minmax(200px,1fr) minmax(200px,1fr) 1fr;
  grid-auto-flow: column;
  column-gap: 20px;
}
@mixin form-section-container-mixin{
  display: grid;
  grid-auto-flow: row;
  row-gap: 20px;
  white-space: pre-line;
}

@mixin error-field-text{
  color: rgb(211, 47, 47);
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin: 3px 14px 0px;
}
