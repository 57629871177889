.dialogContainer{
  display: grid;
  grid-gap: 10px;
}
.dialogTitleContainer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dialogTitle{
  font-weight: 800;
  font-size: 1.25rem;
  line-height: 1.6;
  margin: 0;
  padding-right: 10px;
}
