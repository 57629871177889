.wrapper {
  display: grid;
  grid-template: 90px 1fr / 1fr;
  grid-row-gap: 2rem;
  margin-bottom: 1rem;

  padding: 20px;
}

.hierarchy-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20rem;
  background-color: #ffffff;
  border-radius: 3px;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84vh;
  width: 100%;

  background-color: #ffffff;
  border-radius: 3px;

  color: #000000;
}

.tooltip {
  color: #000000;
  font-size: 0.8rem;

  background-color: #ffffff;
  width: auto;
  min-height: 2rem;
  padding: 0.4rem;
  border: 1px solid #cdcdcd;
  border-radius: 0.3rem;
}

.tooltip-content {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
}

.tooltip-content-title {
  font-weight: 500;
  margin: 0 0 0.2rem 0;
}

.tooltip-link {
  display: block;
  color: #000000;
  cursor: pointer;
  pointer-events: initial;

  padding: 4px;
  border-radius: 8px;

  &:hover {
    background-color: $color-background-default;
  }
}

.parent-node,
.child-node {
  cursor: pointer;
  rect {
    fill: $color-background-shade;
    stroke: $night-sky;
    stroke-width: 1px;
    stroke-dasharray: 3 2;
  }
  text {
    font-family: 'Roboto', sans-serif;
    text-anchor: middle;
    fill: $slate;
    pointer-events: none;
  }
}

.tracked-node {
  cursor: pointer;
  rect {
    fill: $color-attention;
    stroke: $color-attention;
    stroke-width: 1px;
  }
  text {
    font-family: 'Roboto', sans-serif;
    text-anchor: middle;
    pointer-events: none;
  }
  circle {
    fill: $color-white;
    stroke: $night-sky;
  }
}
.tracked-node-text {
  font-weight: bold;
  fill: $color-copy-contrast;
}

.number-of-children {
  font-size: 8px;
  fill: $color-primary;
}

.rest-node {
  cursor: pointer;

  rect {
    fill: $color-background-default;
    stroke: $night-sky;
    stroke-width: 1px;
    stroke-dasharray: 3 2;
  }

  text {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    text-anchor: middle;
    fill: $color-highlight;
    pointer-events: none;
  }
}

.link {
  stroke: $night-sky;
  stroke-width: 1px;
  fill: none;
}
