//======== Viewport management ============

@mixin device($canvas) {
	@if $canvas == ultra-mobile-only {
		@media only screen and (max-width: $mobile - 1) { @content; } 
	}
	@if $canvas == mobile-only {
		@media only screen and (max-width: $tablet-portrait - 1) { @content; } 
	}
	@if $canvas == tablet-portrait {
		@media only screen and (min-width: $tablet-portrait) { @content; } 
	}
	@if $canvas == tablet {
		@media only screen and (min-width: $tablet) { @content; } 
	}
	@if $canvas == desktop {
		@media only screen and (min-width: $desktop) { @content; } 
	}
	@if $canvas == full {
		@media only screen and (min-width: $full) { @content; } 
	}
	@if $canvas == full-height {
		@media only screen and (min-height: 768px) { @content; } 
	}
}

@mixin viewport-wildcard($viewport) {
	@media only screen and (min-width: $viewport + 'px') { @content; } 
}

//======== Configs and resets ============

@mixin box-sizing-reset($model) {
	html {
	  box-sizing: $model;
	}
	*, *:before, *:after {
	  box-sizing: inherit;
	}
}

@mixin body-fonts($font-stack, $font-weight) {
	body,
	textarea {
	  font-family: $font-stack;
	  font-weight: $font-weight;
	  font-size: 100%;
	  font-size: $fs-copy;
	}
}