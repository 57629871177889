.hierarchyLevelContainer{
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-auto-flow: column;
  column-gap: 10px;
  margin-top: 20px;

  @media screen and (max-width:900px) {
    grid-template-columns:1fr;
    grid-auto-flow: row;
    row-gap: 10px;
    margin-bottom: 20px;
  }
}
.row1{
  display: grid;
  column-gap: 10px;
  grid-auto-flow: column;
  grid-template-columns: 60px minmax(80px,110px) 1fr;
}
.row2{
  display: grid;
  column-gap: 10px;
  grid-auto-flow: column;
  grid-template-columns:minmax(70px,80px) minmax(60px,70px) ;
  @media screen and (max-width:900px) {
    width: 100%;
    grid-template-columns:1fr 1fr
  }
}
.row3{
  display: grid;
  column-gap: 10px;
  grid-auto-flow: column;
  grid-template-columns: 1fr;
}
.subRow3{
  display: grid;
  grid-template-columns:1fr 1fr;
  grid-column-gap: 10px;
}

.levelButtonsContainer{
  display: grid;
  grid-template: 1fr / auto auto;
}
.arrowButtonsContainer{
  display: grid;
  justify-content: center;
  align-content: center;
}
