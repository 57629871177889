// @import "~@track-and-trace/core-module/src/styles/global";
@mixin rounded-corners {
  border-radius: 12px;
}

.map-wrapper {
  display: grid;
  grid-template: 1fr / 1fr 2fr;
  justify-content: center;
  align-items: center;

  height: 100%;
  width: 100%;

  margin: 0;

  img {
    max-width: 100%;
  }
}

.map {
  overflow: hidden;
  border-bottom-right-radius: 12px;
  div {
    border-radius: 0;
  }
}

.map-sidebar {
  position: relative;
  align-self: flex-start;

  display: grid;
  grid-template: auto / 1px 1fr;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  align-items: flex-start;
  row-gap: 8px;

  width: 100%;
  padding-left: 16px;
}

.line-wrapper {
  position: absolute;
  height: 100%;
  padding-top: 62px;
  padding-bottom: 32px;
  left: 31.5px;
  width: 1px;
}

.line {
  height: 100%;
  width: 2px;
  background: repeating-linear-gradient(to bottom, #333382 0px, #333382 5px, transparent 5px, transparent 8px) /*3px red then 4px transparent -> repeat this!*/
}

.map-sidebar-title {
  grid-column: 1 / span 2;

  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: $color-text;

  margin: 20px 0 6px 0;
}

.map-point-name {
  grid-column: 2 / span 2;

  font-size: 14px;
  font-family: Roboto, sans-serif;
  color: $color-text;

  margin-top: 2px;
}

.map-point-info {
  grid-column: 2;

  display: grid;
  grid-template: auto auto / 24px auto auto;
  row-gap: 4px;
  column-gap: 12px;

  justify-items: flex-start;

  margin-bottom: 6px;
  z-index: 2;
  cursor: pointer;

  padding: 8px 4px 12px 4px;
}

.map-point-info-active {
  cursor: initial;

  .map-point-name {
    color: $color-highlight;
    font-weight: 500;
  }
}

.map-point-number {
  @include rounded-corners;
  grid-row: span 2;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: $color-text;

  color: $color-white;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
}

.map-point-number-active {
  background-color: $color-highlight;
}


.map-point-gln {
  grid-column: 2;

  font-size: 12px;
  font-family: Roboto, sans-serif;
  color: $color-primary;


}

.map-point-event-date {
  grid-column: 3;

  font-size: 12px;
  font-family: Roboto, sans-serif;
  color: $color-primary;
}