@import "generalСlasses.scss";

.summaryContainer {
  @include summary-container-mixin;
}

.summaryTabMenuHeader {
  @include tab-menu-header-mixin;
}

.summaryMain {
  @include main-scroll-container-mixin;
}

.headerButtonContainer {
  @include header-button-container-mixin;
  min-width: 300px;
}

.taskListHeaderButtonContainer {
  @include header-button-container-adaptive-mixin (800px);
}

.actionButtonsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-template-rows: auto auto;
  grid-gap: 5px;
  justify-items: stretch;
  align-items: stretch;
}
.actionButton{
  min-width: 180px;
  padding: 2px 7px!important;
  margin-right: 5px;
}

.headerButtonContainerAdaptive {
  @include header-button-container-adaptive-mixin (1200px)
}

.headerResultList {
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}


.formContainer {
  @include form-container-mixin;
}

.formRow {
  @include form-row-mixin;

  @media screen and (max-width: 900px) {
    grid-auto-flow: row;
    grid-template-columns:  repeat(auto-fill, 200px);
    row-gap: 15px;
  }
}

.formContainerSection {
  @include form-section-container-mixin;
}


.hierarchySection {
  padding-bottom: 20px;
  @media screen and (max-width: 500px) {
    display: grid
  }
}

.hierarchySectionHelperText {
  text-align: center;
}

.taskContainer {
  width: 100%;
  display: grid;
  grid-template: 1fr / 5fr 2fr;
  column-gap: 20px;
  padding: 20px;
  @media screen and (max-width: 500px) {
    row-gap: 20px;
    grid-template: max-content max-content / 1fr ;
  }
}

.logsStatusContainer {
  color: red;
  display: grid;
  cursor: pointer;
  grid-auto-flow: column;
  justify-content: start;
  align-content: center;
}

.errorLogsContainer {
  display: grid;
  grid-template-rows: min-content max-content;
  grid-auto-flow: column;
}
.detailPanelColumnStyle {
  background-color: #8A899D;
}
