.container{
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 3;
  justify-content: center;
  align-items: center;
}
.spinner{
  z-index: 1;
  width: 40px;
  height: 40px;
  opacity: 1;
}
.block{
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.5);
  backdrop-filter: blur(0.5px);
  position: absolute;
  z-index: 0;
}
