
// #################

// Subatomic tokens

// #################

// Environment

$full-width: 1480px; // Widest layout wrapper

$mobile: 360px;
$tablet-portrait: 768px;
$tablet: 1024px;
$desktop: 1280px;
$full: 1680px;

// Color palette

// Brand colors
$cyberlachs: #00C3A0;
$neon-mint: #5bebc5;
$cornflower: #5784ff;
$blue-plum: #6678c4;

// Light colors
$white: #fff;
$off-white: #f2f2f9;
$off-white-light: #f5f6f9;
$blueberry-skyr: #e6e8f4;

// Dark colors
$night-sky: #293358;
$slate: #737a95;

// Additional colors
$shadow-color-default: rgba(30,40,70,.1);
$shadow-color-dark: rgba(30,40,70,.1);

// Typography

$font-stack-default: 'Roboto', -apple-system, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
$fw-black: 900;

$fs-s: 14px;
$fs-copy: 16px;
$fs-m: 18px;
$fs-l: 20px;
$fs-xl: 26px;
$fs-xxl: 36px;
$fs-xxxl: 42px;

// Spacing

$spacing-xxxs: 4px;
$spacing-xxs: 6px;
$spacing-xs: 12px;
$spacing-s: 16px;
$spacing-m: 20px;
$spacing-l: 26px;
$spacing-xl: 42px;
$spacing-xxl: 56px;
$spacing-xxxl: 64px;

// Styling

$border-radius-s: 8px;
$border-radius-m: 42px;
$shadow-size-m: 0 6px 12px;


// #################

// Semantic mappings

// #################

// Color scheme: Kvinta Light

$color-primary: #333381; // Kvinta dark blue
$color-secondary: #03C3A0; // Kvinta green
$color-additional: #8A899D; // Kvinta slate
$color-attention: $cyberlachs;

$color-background-default: $white;
$color-background-shade: $off-white-light;
$color-background-contrast: $night-sky;
$color-border-light: $blueberry-skyr;

$color-copy: $night-sky;
$color-copy-contrast: $white;
$color-body-copy: $slate;

$color-icon-default: $night-sky;
$color-icon-contrast: $white;

$color-notification-badge: $color-primary;
$color-notification-badge-copy: $color-copy-contrast;

$color-button-default: $color-secondary;
$color-button-cta: $color-primary;
$color-button-attention: $color-attention;

$color-content-shaded: $off-white-light;

// Color scheme: Dark

$color-primary-dark: $cornflower;
$color-secondary-dark: $off-white;
$color-additional-dark: $blue-plum;
$color-attention-dark: $cyberlachs;

// Shapes and spacings

// Input elements

	// Default
	$spacing-button-default: $spacing-s $spacing-m;
	$border-radius-input: $border-radius-s;

// Content containers

	// Bubble box
	$spacing-bubble-box: $spacing-l;
	$border-radius-bubble-box: $border-radius-s;

	//Footer
	$spacing-footer: $spacing-s $spacing-l;

  // Timeline

  $color-text: $color-primary;
  $color-highlight: $color-attention;
  $color-white: $white;